<template>
  <div class="wrapper flex flex-direction-col justify-center items-center h-full">
    <img src="@/assets/shared/gear-loading.svg" class="loader" />
    <div class="loading-text">
      <h1 class="loading">
        LOADING YOUR SCREEN
        <span class="dot-one"> .</span>
        <span class="dot-two"> .</span>
        <span class="dot-three"> .</span>
      </h1>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { cloneDeep, get } from "lodash";
import axios from "axios";
import qs from "querystring";
import jwtdecode from "jwt-decode";
import notification from "@/utils/notifications";
import userMe from "@/services/user-me";
import USER_TYPE_CONSTANTS from "@/constants/user-type-constants";
import Loading from "@/components/common/AntDesign/Loading.vue";

export default {
  name: "AuthCallback",
  components: {
    Loading
  },
  data() {
    return {
      userType: ""
    };
  },
  created() {
    if (localStorage.hydraState === this.$route.query.state) {
      this.fetchTokens();
    }
  },
  methods: {
    ...mapActions({
      addToCart: "marketplaceProduct/addToCart",
      addLocalProductsToCart: "marketplaceProduct/addLocalProductsToCart",
      fetchLocationDetailsById: "fetchLocationDetailsById"
    }),
    ...mapActions("usersModule", ["initializeCurrentLoggedInInfo"]),
    fetchTokens() {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };
      let id;
      this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
      if (this.userType === "practiceUser") {
        id = `${process.env.VUE_APP_PATIENT_CLIENT_ID}`;
        // id = `${process.env.VUE_APP_BHRT_CLIENT_ID}`;
      } else {
        id = `${process.env.VUE_APP_CLIENT_ID}`;
      }
      const body = {
        client_id: id,
        code: this.$route.query.code,
        grant_type: "authorization_code",
        redirect_uri: `${process.env.VUE_APP_SELF_ROOT}/callback`
      };

      axios.post(`${process.env.VUE_APP_AUTH_ROOT}/api/oauth2/token`, qs.stringify(body), config).then(async response => {
        const loginTokenId = response.data.id_token || null;
        const loginAccessToken = response.data.access_token || null;
        const decodedToken = jwtdecode(loginTokenId);
        const loginExpTime = decodedToken.exp;
        const { userType } = decodedToken.sim.user;
        const selectedVendor = localStorage.getItem("selected_vendor");
        const isSimpatraSuperAdmin = get(decodedToken.sim, "simpatraAdmin", false);

        localStorage.setItem("access_token", loginAccessToken);
        localStorage.setItem("id_token", loginTokenId);
        localStorage.setItem("exp", loginExpTime);

        await this.initializeCurrentLoggedInInfo({
          access_token: loginAccessToken,
          exp: loginExpTime,
          decodedToken
        });

        const isOrgUser = userType === USER_TYPE_CONSTANTS.organizationUser;
        const isPatientUser = userType === USER_TYPE_CONSTANTS.patientUser;
        if (["", null, undefined].includes(selectedVendor)) {
          const key = isOrgUser ? "orgId" : "selected_vendor";
          const value = decodedToken.sim.details.id;
          localStorage.setItem(key, value);
          if (!isSimpatraSuperAdmin) {
            localStorage.removeItem(isOrgUser ? "selected_vendor" : "orgId");
          }
        }

        // CHECKER FOR PATIENT USER TYPE
        // PATIENTS ARE CURRENTLY ABLE TO LOGIN TO PRACTICE PAGE, WILL REMOVE NEXT TIME ONCE BACKEND IS FIXED
        if (isPatientUser) {
          notification("warning", "Please log in as organization user.");
        }

        const callbackRoute = cloneDeep(localStorage.getItem("callbackRoute"));
        const hasCallback = !["", null, undefined].includes(callbackRoute);

        // LANDING PAGE CHECKER AFTER LOGIN
        if (userMe.getMyRoles().isStandardUser || userMe.getMyRoles().isVendorUser) {
          if (hasCallback) {
            this.$router.push({ path: callbackRoute });
            localStorage.removeItem("callbackRoute");
          } else {
            this.$router.push({ path: "/" });
          }
        } else {
          if (callbackRoute !== "/" && hasCallback) {
            this.$router.push({ path: callbackRoute });
            localStorage.removeItem("callbackRoute");
          } else {
            this.$router.push({ name: "OrgLocations" });
          }
        }
      });
    },
    isOrgOwnerOrAdmin(dt) {
      if (dt.sim.user.userType === "ORG") {
        return dt.sim.details.isAdmin || dt.sim.details.isOwner;
      }
      return false;
    }
  }
};
</script>

<style type="scss" scoped>
.loader {
  width: 15rem;
}
h1.loading {
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #9E9E9E;
}
span[class^="dot-"] {
  opacity: 0;
}
.dot-one {
  animation: dot-one 2s infinite linear;
}
.dot-two {
  animation: dot-two 2s infinite linear;
}
.dot-three {
  animation: dot-three 2s infinite linear;
}
@keyframes dot-one {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>

