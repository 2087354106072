const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

const convertBlobFileUrl = (blobFile) => {
  return window.URL.createObjectURL(
    new Blob([blobFile], {
      type: blobFile.type,
    })
  );
};

export default {
  getUrlExtension,
  convertBlobFileUrl,
};
