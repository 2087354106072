<template>
  <LayoutCentered>
    <router-view />
  </LayoutCentered>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutCentered from '@/components/common/LayoutCentered.vue';

export default {
  name: 'Support',
  components: {
    LayoutCentered,
  },
  computed: {
    ...mapGetters([
      'getUserLoggedInStatus',
    ]),
    loggedInOnConsultations() {
      return this.getUserLoggedInStatus && this.$route.name === 'DosingConsultations';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
