const d2h = function dec2hex(dec) {
  return (`0${dec.toString(16)}`).substr(-2);
};

export const hydraState = function generateHydraState() {
  const arr = new Uint8Array((23) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, d2h).join('');
};

export const setCallbackConfigAndRedirect = () => {
  if (!localStorage.callbackRoute) {
    localStorage.callbackRoute = '/';
  }
  localStorage.hydraState = hydraState();
  // localStorage.currentLocation = locationId;
  const url = `${process.env.VUE_APP_AUTH_ROOT}/api/oauth2/auth`
        + `?client_id=${process.env.VUE_APP_CLIENT_ID}`
        + '&scope=openid'
        + '&response_type=code'
        + `&state=${localStorage.hydraState}`;
  window.location = url;
};

export const setCallbackConfigForPatient = (locationId) => {
  if (!localStorage.callbackRoute) {
    localStorage.callbackRoute = '/';
  }
  localStorage.hydraState = hydraState();
  const url = `${process.env.VUE_APP_AUTH_ROOT}/api/oauth2/auth`
        + `?client_id=${process.env.VUE_APP_PATIENT_CLIENT_ID}`
        + '&scope=openid'
        + '&response_type=code'
        + `&state=${localStorage.hydraState}`;
  window.location = url;
};

export const setCallbackConfigForPatientDirectory = () => {
  if (!localStorage.callbackRoute) {
    localStorage.callbackRoute = '/';
  }
  localStorage.hydraState = hydraState();
  const url = `${process.env.VUE_APP_AUTH_ROOT}/api/oauth2/auth`
        + `?client_id=${process.env.VUE_APP_PATIENT_PORTTAL_CLIENT_ID}`
        + '&scope=openid'
        + '&response_type=code'
        + `&state=${localStorage.hydraState}`;
  window.location = url;
};
