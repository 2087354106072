import { find, isEmpty, set } from "lodash";
import store from "../store";

// Active Location Checker
const isLocationActive = async (locationId) => {
  const availableLocations = await store.getters["locationsModule/getAllAvailableLocations"];
  const location = find(availableLocations, (location) => location.id === locationId);

  return location ? location.isActive : false;
};

const getLocationAndValidate = (locationId) => {
  const availableLocations = store.getters["locationsModule/getAllAvailableLocations"];
  const location = find(availableLocations, (location) => location.id === locationId);

  return !isEmpty(location) ? location : null;
};

const addPropertyIfNotEmpty = (object, propertyName, propertyValue) => {
  const isNumber = typeof propertyValue === "number";
  if (isNumber || !isEmpty(propertyValue)) {
    set(object, propertyName, propertyValue);
  }
};

// const isLocationRegistrationToVendor = (vendorId, locationId) => {
//     const availableRegistrations = store.getters["locationRegistrations/fetchRegistrations"];
//     const vendorRegistration = availableRegistrations.find((registration) => {
//       return registration.vendor.id === vendorId && registration.location.id === currentLocation;
//     });
//     return !isEmpty(vendorRegistration) && vendorRegistration && vendorRegistration.status === "APPROVED";
// }

const getLocationRegistrationToVendor = ({ vendorId, locationId }) => {
  const availableRegistrations = store.getters["locationRegistrations/getRegistrations"];
  const vendorRegistration = availableRegistrations.find((registration) => {
    const registrationLocationId = registration.location.id;
    const registrationVendorId = registration.vendor.id;
    return registrationVendorId === vendorId && registrationLocationId === locationId;
  });
  return vendorRegistration;
};

const isPastDate = (date) => {
  const now = new Date();
  const dateProvided = new Date(date);
  return dateProvided < now;
};

const validatePhoneNumber = (value) => {
  const phoneRegex = /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
  return phoneRegex.test(value);
};

// US PHONE NUMBER FORMAT
const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export default {
  isLocationActive,
  getLocationAndValidate,
  addPropertyIfNotEmpty,
  validatePhoneNumber,
  getLocationRegistrationToVendor,
  isPastDate,
  formatPhoneNumber,
};
