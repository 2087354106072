export default class ValidationHelper {
  /**
   * @function isNumberInput
   * @param {evt}
   * @returns {Boolean}
   */
  isNumberInput(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCoode;
    // 46 is dot
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    } else {
      return true;
    }
  }

  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
