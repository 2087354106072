<template>
  <div />
</template>

<script>
import { mapGetters } from 'vuex';
import { setCallbackConfigAndRedirect } from '@/utils/auth';

export default {
  name: 'LogIn',
  computed: {
    ...mapGetters(['getSelectedCurrentLocationId']),
  },
  mounted() {
    setCallbackConfigAndRedirect(this.getSelectedCurrentLocationId);
  },
};
</script>

<style>

</style>
