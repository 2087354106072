import pharmacyIcon from '@/assets/placeholders/pharmacy.png';
import nutraceuticalsIcon from '@/assets/placeholders/nutraceuticals.png';
import medicalEquipmentIcon from '@/assets/placeholders/medical-equipment.png';
import pharmaceuticalsIcon from '@/assets/placeholders/pharmaceuticals.png';
import medicalSuppliesIcon from '@/assets/placeholders/medical-supplies.png';

// eslint-disable-next-line import/prefer-default-export
export const findCategoryImage = (primaryCategory) => {
  switch (primaryCategory) {
    case 'Pharmacy': return pharmacyIcon;
    case 'Nutraceuticals': return nutraceuticalsIcon;
    case 'Medical Equipment': return medicalEquipmentIcon;
    case 'Pharmaceuticals': return pharmaceuticalsIcon;
    case 'Medical Supplies': return medicalSuppliesIcon;
    default: return 'https://via.placeholder.com/300x300/eeeeee/8f9398?text=No%20Image%20Provided';
  }
};
