import anime from 'animejs';

export const translateX = (element, newPosition) => {
  anime({
    targets: element,
    translateX: `${newPosition}`,
    easing: 'easeOutCubic',
  });
};

export const translateY = (element, newPosition) => {
  anime({
    targets: element,
    translateY: `${newPosition}`,
    easing: 'easeOutCubic',
  });
};

export const numberTransition = (object, key, newValue) => {
  anime({
    targets: object,
    round: 2,
    easing: 'linear',
    duration: 250,
    [key]: newValue,
  });
};

export const scalePop = (element, value) => {
  anime({
    targets: element,
    scale: value,
    direction: 'alternate',
    duration: 350,
    easing: 'easeInQuint',
  });
};

export const swayX = (element, value, time) => {
  anime({
    targets: element,
    translateX: value,
    loop: true,
    duration: time,
    direction: 'alternate',
    easing: 'easeInOutQuad',
  });
};
